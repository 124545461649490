import React from "react"
//import Banner from "../../components/Banner/banner"
import TextBlock from "../../components/TextBlock/textBlock"
//import Button from "../../components/Button/button"
//import { Link } from "react-scroll"

//import { IconContext } from "react-icons"
//import { MdDone, MdClear } from "react-icons/md"

const HomePage = () => {
  return (
    <>
      <TextBlock
        id="terms_of_service"
        title=""
        paragraph=""
      >
        <h1>【利用規約とプライバシーポリシー】</h1>
        <h2>はじめに</h2>
        Deraii.com（以下、「当方」）は、WEB アプリ、スマートフォン用のアプリケーションのサービスを提供しています。<br />
        当方のアプリのご利用により、本規約に同意したものとみなさせていただきます。
        <br />

        <h2>利用規約</h2>
        <h3>免責事項</h3>
        当方は、本サービスの内容変更、中断、終了によって生じたいかなる損害についても、一切責任を負いません。<br />
        当方は、利用者の本サービスの利用環境について一切関与せず、また一切の責任を負いません。<br />
        本サービスの利用に関し当方が損害賠償責任を負う場合、当該損害が発生した月に該当利用者から受領した利用額を限度として賠償責任を負うものとします。<br />
        <br />

        <h3>禁止事項</h3>
        アプリの利用に際し、当方は、利用者に対し、次に掲げる行為を禁止します。<br />
        当方において、利用者が禁止事項に違反したと認めた場合、利用の一時停止、退会処分その他当方が必要と判断した措置を取ることができます。<br />
        当方または第三者の知的財産権を侵害する行為。<br />
        当方または第三者の名誉・信用を毀損または不当に差別もしくは誹謗中傷する行為。<br />
        上記の他、当方が不適切と判断する行為。
      </TextBlock>

      <TextBlock
            id="privacy"
        title=""
        paragraph=""
      >
        <h2>プライバシーポリシー</h2>
        <h3>個人情報の取得について</h3>
        当方は、偽りその他不正の手段によらず適正に個人情報を取得致します。<br />
        <br />

        当方のアプリでは、下記のAPIを利用しています。<br />
        <h4>・端末確認君</h4>
        広告配信のために Google AdMob、利用状況解析のために Google Firebase Analytics を使用しています。<br />
        また、「端末確認君」では下記のAPIを利用して各種情報を取得しています。<br />
        ※ 外部サービスのプライバシーポリシーは以下のリンク先からご確認いただけます。<br />
        <br />
        <br />

        > deraii.com API<br />
        　IPアドレス、ユーザーエージェントなどの端末情報を取得。<br />
        <br />

        >&nbsp;
        <a
          href="https://policies.google.com/technologies/ads?hl=ja"
          target="_blank"
          rel="noopener noreferrer"
        >
          AdMob（Google Inc.）
        </a>
        <br />
        <br />
        >&nbsp;
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Firebase Analytics（Google Inc.）
        </a>
        <br />

        <h4>・クマさんのデジタル時計</h4>
        外部APIの利用はありません。<br />
        <br />

        <h3>個人情報の利用について</h3>
        当方は、個人情報を以下の利用目的の達成に必要な範囲内で、利用致します。<br />
        お問い合わせに対する返信のため。<br />
        本アプリの品質向上のため。<br />
        有料サービスにおいて、ユーザに利用料金を請求するため。<br />
        <br />

        <h3>個人情報の安全管理について</h3>
        当方は、取り扱う個人情報の漏洩、滅失またはき損の防止その他の個人情報の安全管理のために必要かつ適切な措置を講じます。<br />
        <br />

        <h3>個人情報の第三者提供について</h3>
        当方は、個人情報保護法等の法令に定めのある場合を除き、個人情報をあらかじめご本人の同意を得ることなく、第三者に提供致しません。<br />
        <br />

        <h3>個人情報の開示・訂正等について</h3>
        当方は、ご本人から自己の個人情報についての開示の請求がある場合、速やかに開示を致します。<br />
        その際、ご本人であることが確認できない場合には、開示に応じません。<br />
        個人情報の内容に誤りがあり、ご本人から訂正・追加・削除の請求がある場合、調査の上、速やかにこれらの請求に対応致します。<br />
        その際、ご本人であることが確認できない場合には、これらの請求に応じません。<br />
        <br />

        <h3>本方針の変更</h3>
        本方針の内容は変更されることがあります。<br />
        変更後の本方針については、 当方が別途定める場合を除いて、当サイトに掲載した時から効力を生じるものとします。<br />
        <br />

        <h3>連絡先</h3>
        当方の個人情報の取り扱いにつきまして、上記の請求・お問い合わせ等ございましたら、下記までご連絡くださいますようお願い申し上げます。<br />
        taktaky★gmail.com<br />
        ※「★」記号を「@」に置き換えて下さい。<br />
        <br />
        2020 年 12 月 29 日初版<br />
        <br />
        Deraii.com<br />
        <br />

      </TextBlock>
    </>
  )
}

export default HomePage
